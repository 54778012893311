.loading-parent {
  position: relative;
}

.loading {
  text-align: center;
  z-index: 100;
}

.loading svg {
  width: 100%;
  max-width: 300px;
  margin-bottom: -70px;
}

.loading h2 {
  font-weight: 300;
}

.loading,
.loading-icon,
.loading-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loading-icon {
  top: auto;
}

.loading-backdrop {
  background-color: #fff;
  opacity: .7;
}

.loading-wrapper {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-logo{
  height: 100px;
  width: 100px;
}

.loading-bar{
  height: 40px;
  width: 200px;
  margin: auto;
}
