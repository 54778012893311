:root {
  --primary: #2274c6;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 10px !important;
  background-color: #f5f5f5 !important;
}

html {
  font-size: 13px;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: #fbfbfb;
  font-family: 'Lato', 'Noto Sans Thai';
  color: #26292c;
}

button,
label,
input,
textarea,
select,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: 'Lato', 'Noto Sans Thai' !important;
}

::placeholder {
  color: #aaa;
}

.flex {
  display: flex;
}

.black {
  color: #000 !important;
}

@font-face {
  font-family: 'Lato';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-weight: bold;
  font-style: normal;
  src: url('./fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/NotoSansThaiUI-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: bold;
  font-style: normal;
  src: url('./fonts/NotoSansThaiUI-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Thai';
  font-weight: 100;
  font-style: normal;
  src: url('./fonts/NotoSansThaiUI-Light.ttf') format('truetype');
}

.display-none {
  display: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-strike {
  text-decoration: line-through;
}

.text-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.noselect {
  user-select: none;
}

.table-white {
  background-color: #fff;
}

.table .thead-light th {
  background-color: #f0efef;
}

.pushing-action-bar {
  padding-top: 59px;
  background-color: #f8f7f7;
}

.btn.btn-primary {
  min-width: 120px;
  height: 30px;
  border-radius: 5px;
  color: #fff;
  background: linear-gradient(248deg, #39a6d7, #66e0a0);
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid transparent;
  background-origin: border-box;
}
.btn-primary:hover {
  cursor: pointer;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
  color: #518fcc;
  background: #fff;
  border: 1px solid #518fcc;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.14), 0 3px 3px rgba(0, 0, 0, 0.11);
}

.btn-primary.disabled,
.btn-primary:disabled {
  cursor: default;
  color: #fff;
  background-color: #dedede;
  background-image: none;
  border-color: #dedede;
}

.btn-outline-primary {
  width: 110px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: #fff;
  margin-top: 3px;
  margin-left: 16px;
  background-image: linear-gradient(248deg, #39a6d7, #66e0a0);
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #518fcc;
  background: #fff;
  border: 1px solid #518fcc;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.14), 0 3px 3px rgba(0, 0, 0, 0.11);
}

.card-with-shadow {
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.ui.blue.button,
.ui.blue.buttons {
  background-color: #77b157;
}

.ui.blue.button:hover,
.ui.blue.buttons:hover,
.ui.blue.button:focus,
.ui.blue.buttons:focus {
  background-color: #589835;
  border-color: #589835;
}

.modal-content {
  border-radius: 0;
}

.border-none {
  border: none;
}

.form-control {
  color: #26292c;
  border-radius: 0;
  border: 1px solid #abacae;
}

.padding-top-5px {
  padding-top: 5px;
}

.fontawesome-styled.active {
  color: #ffff;
}

.fontawesome-styled.un-active {
  color: #c0c0c0;
}

.fontawesome-styled.circle {
  border-radius: 100%;
  background-color: #2960bd;
}

.fontawesome-size-m {
  font-size: 17px;
}

.modal-title {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: normal;
}

.modal-dialog {
  height: 95%;
}
.modal-content {
  height: auto;
  min-height: 1px;
  max-height: 100%;
}

.modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-open .modal {
  overflow-y: hidden;
}

.modal-footer {
  display: block;
  align-items: center;
  justify-content: stretch;
  padding: 0;
}

.loading-div {
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 50px;
  padding-top: 300px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.red-text {
  color: #ff0000;
}

.link {
  color: var(--primary);
  cursor: pointer;
}

button:focus {
  outline: 0;
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

.hidden-button {
  background: none;
  border: none;
  color: #757575;
  font-size: 15px;
  height: 25px;
  margin-left: 15px;
}

.invisible-bt {
  border: none;
  background: none;
  cursor: pointer;
}

.invisible-bt:focus {
  outline: none;
}

.direction-rtl {
  direction: rtl;
}

@keyframes bounceInDownDetail {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDownDetail {
  -webkit-animation-name: bounceInDownDetail;
  animation-name: bounceInDownDetail;
}

.no-scroll {
  overflow-y: hidden;
}

.move-deal .popover {
  max-width: 325px;
  width: 325px;
  padding: 15px;
}

.move-deal .arrow {
  display: none;
}