.error-pages {
    background: #fbfbfb;
    height: 100vh;
    padding-top: 80px;
}

.error-pages img {
    width: 100%;
    max-width: 600px;
}

.error-pages img.connection-error {
    max-width: 300px;
}

.error-pages .error-container {
    width: 100%;
    max-width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.error-pages .error-text {
    text-align: center;
    margin-left: -250px;
    margin-top: -100px;
}

.error-pages .error-text h2 {
    font-size: 49px;
    color: #9eb5cc;
    font-weight: 300;
}

.error-pages .error-text p {
    font-size: 27px;
    color: #9eb5cc;
    font-weight: 300;
}

.error-button {
    width: 280px!important;
    font-size: 21px!important;
    height: 48px!important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}


.flex {
  display: flex;
}

.user-suspended {
  background: #fbfbfb;
  padding: 40px;
}

.user-suspended .container {
  width: 100%;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.user-suspended .container h2 {
  font-size: 24px;
  color: #26292c;
  margin-bottom: 25px;
}

.user-suspended .container p {
  font-size: 15px;
  color: #26292c;
  font-weight: 300;
}

.user-suspended .admins-wrapper {
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: left;
  flex-wrap: wrap;
}

.user-suspended .admins-wrapper .item {
  flex: 50%;
  margin-bottom: 20px;
}

.user-suspended .admins-wrapper h6 {
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 5px;
}

.user-suspended .admins-wrapper p{
  font-size: 13px;
  font-weight: 300;
  padding-left: 5px;
}

.user-suspended .graphic {
  margin: 30px auto;
}

.user-suspended .actions {
  margin-top: 35px;
}

.user-suspended .actions button {
  border: none;
  color:#495e72;
  font-size: 15px;
}
