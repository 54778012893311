
.forgetpw-button{
  width: 220px !important;
  height: 48px !important;
  margin: 15px auto;
  font-size: 20px;
  text-align: center;
}

.signupCard {
  margin-top: 90px;
  padding-bottom: 10px;
  border-radius: 12px;
  box-shadow: 1.5px 2.598px 9.9px 0.1px rgba(118, 114, 114, 0.2);
  width: 100%;
  max-width: 529px;
  padding: 0 20px;
}

.signupCard img {
  max-width: 200px;
  margin: 10px auto 20px;
}

.signupCard img.email.sent{
  max-width: 300px;
  margin: -10px auto 0;
}

.signupCard h3.card-title {
  font-size: 32px;
  color: #333;
}

.signupCard p.card-text {
  font-size: 20px;
  color: #999999;
}

.email-resent-text{
  color: var(--primary);
  cursor: pointer;
  background: none;
  border: none;
}